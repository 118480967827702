<template>
    <div class="p-2">

        <!-- NAV HEADER -->
        <div class="d-flex mt-2">
            <ul class="m-auto nav nav-pills">
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="border-radius: 10px 0 0 10px;background-color: white;color:#1B628C;font-size: 16px;border:2px solid transparent"
                        to="/activities">My Activities</router-link>
                </li>
                <li class="nav-item">
                    <router-link class="nav-link font-weight-bold" 
                        style="color:white;background-color: #1EB4F1;border-radius: 0 10px 10px 0;font-size: 16px;border:2px solid #FFAD32" 
                        to="/activities/pet"
                    >
                    Pet Activities</router-link>
                </li>
            </ul>
        </div>
        <!-- END NAV -->

        <div v-if="activities.length < 1" :style="`${$isMobile() ? '' : 'max-width: 500px'};`" class="mx-auto">
            <img src="@/static/undraw_Good_doggy_re_eet7-removebg-preview.png" class="w-100" />
            <h2 class="text-white text-center">No Activities For Now</h2>
        </div>

        <!-- ACTIVITIES LIST -->
        <div class="d-flex flex-column" style="gap:20px">
            <template v-for="(date, key) in activities">
                <template v-for="item in date">
                    <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-if="item.type == 'training'">
                        <div class="d-flex align-items-center" style="gap:10px 20px">
                            <div class="h3 text-white mb-0">Training, {{ item.data.pet.pet_name }}</div>
                            <!-- <i class="bx bxs-file-plus text-primary ml-auto" style="font-size: 22px;"></i> -->
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ item.data.schedule.training.tr_name }}
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ moment(item.data.schedule.trs_date).format('dddd, DD MMMM YYYY') }}, {{ item.data.schedule.trs_time }}
                        </div>

                        <div class="text-white h5 font-weight-bolder" style="margin-top: .5em;">Details: </div>

                        <p class="text-white mb-0" style="margin-top: .5em;">
                            {{item.data.schedule.training.tr_details}}
                        </p>

                        <!-- <div class="d-flex flex-wrap align-items-center justify-content-between" style="gap:10px">
                            <div class="btn" style="
                                border-radius: 15px;
                                border: 2px solid #FFAD32;
                                background: #005071;
                                color: white;
                            ">
                                Set Reminder
                            </div>
                            <div class="btn" style="
                                border-radius: 15px;
                                border: 2px solid #FFAD32;
                                background: #C10000;
                                color: white;
                            ">
                                Cancel Activities
                            </div>
                        </div> -->
                    </div>

                    <div class="p-1" style="border-radius: 10px;border:2px solid #FFAD32" v-else-if="item.type == 'walker'">
                        <div class="d-flex align-items-center" style="gap:10px 20px">
                            <div class="h3 text-white mb-0">{{item.data.service}}, {{ item.data.walker.user_name }}</div>
                            <!-- <i class="bx bxs-file-plus text-primary ml-auto" style="font-size: 22px;"></i> -->
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ item.data.pet.pet_name }}, {{ item.data.pet.pet_breed }}
                        </div>

                        <div class="text-white h5" style="margin-top: .5em;">
                            {{ moment(item.data.ws_date).format('dddd, DD MMMM YYYY') }}, {{ item.data.ws_time }}
                        </div>

                        <!-- <div class="d-flex flex-wrap align-items-center justify-content-between" style="gap:10px">
                            <div class="btn" style="
                                border-radius: 15px;
                                border: 2px solid #FFAD32;
                                background: #005071;
                                color: white;
                            ">
                                Set Reminder
                            </div>
                            <div class="btn" style="
                                border-radius: 15px;
                                border: 2px solid #FFAD32;
                                background: #C10000;
                                color: white;
                            ">
                                Cancel Activities
                            </div>
                        </div> -->
                    </div>
                </template>
            </template>
        </div>
        <!-- END LIST -->

    </div>
</template>

<script>

import store from '@/store'
import moment from 'moment'
import Cookies from 'universal-cookie'
let cookies = new Cookies()

export default {
    computed: {
        activities(){
            return store.state.activities.PET_ACTIVITIES
        }
    },
    methods: {

    },
    mounted(){
        store.dispatch('activities/GetPetActivities', {
            user_id: cookies.get('token')
        })
    },
    data(){
        return {
            moment
        }
    }
}

</script>